.card {
  position: relative;
  z-index: 0;
  float: left;
  width: 280px;
  height: 240px;
  margin: 20px;
  color: white;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 3px 6px #ccc;
}

.card:hover {
  text-decoration: underline;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  transform: scale(1.02, 1.02);
}

.card::before {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  content: '';
  background: inherit;
  filter: brightness(30%);
  border-radius: 10px;
}

.card a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
}

.card_title {
  font-size: 150%;
}

/* 画面幅580px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 580px) {
  /* InfoCard style */
  .card {
    width: 90%;
  }
}
