#actFab {
  position: fixed;
  right: -60px;
  bottom: 30px;
  width: 48px;
  height: 48px;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  transition: 0.5s;
  appearance: none;
}

#actFab.show {
  transform: translateX(-160%);
}
