.pankuzu {
  padding-bottom: 20px;
  padding-left: 0;
  margin-left: 10px;
  font-size: small;
}

.pankuzu li {
  display: inline;
  font-weight: bold;
  list-style: none;
}

.pankuzu li::after {
  padding: 0 10px;
  content: '>';
}

.pankuzu li:last-child::after {
  content: '';
}

.pankuzu li a {
  text-decoration: none;
}

.pankuzu li a:hover {
  text-decoration: underline;
}
