.articleHeader {
  position: fixed;
  z-index: 9999;
  width: 100%;
  padding-left: 10px;
  font-size: xx-large;
  text-align: left;
  background-size: cover;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.11);
}

.articleHeader h1 {
  font-weight: bold;
}
