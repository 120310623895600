.side {
  padding: 0;
  margin: 0;
  margin-right: 10px;
  margin-left: 5px;
}

.tocH2 {
  padding-left: 10px;
  margin-top: 5px;
}

.tocH3 {
  padding-left: 20px;
}

.toc_btn {
  padding: 0;
  margin-top: -5px;
  font-family: inherit;
  font-size: 100%;
  color: inherit;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
}

.highlight {
  background: #eee;
}
