.product_tile {
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: 175px;
  height: 175px;
  margin: 5px;
  text-align: center;
}

.product_tile_area > .product_tile:last-child {
  flex: none;
}

.product_image {
  max-width: 150px;
  height: auto;
}

.product_image_long {
  width: auto;
  max-height: 150px;
}

.show_product_data_btn {
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
}

.product_data {
  display: grid;
  display: none;
  grid-template-rows: 28% 18% 18% 18% 18%;
  grid-template-columns: 20% 80%;
  width: 100%;
  height: 300px;
  padding-bottom: 20px;
  word-wrap: break-word;
  background-color: lightcyan;
}

.product_data > div {
  margin: 15px 5px;
  line-height: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  1% {
    display: grid;
  }

  100% {
    opacity: 1;
  }
}

.product_data.show {
  display: grid;
  animation: fadeIn 1s;
}

.product_title {
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  font-size: xx-large;
  font-weight: bold;
  text-align: center;
  word-wrap: break-word;
}

.product_url_title {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.product_url_text {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.product_src_title {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
}

.product_src_text {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
}

.product_info_title {
  grid-row: 4 / 5;
  grid-column: 1 / 2;
}

.product_info_text {
  grid-row: 4 / 5;
  grid-column: 2 / 3;
}

.product_skill_title {
  grid-row: 5 / 6;
  grid-column: 1 / 2;
}

.product_skill_text {
  grid-row: 5 / 6;
  grid-column: 2 / 3;
}

.product_skill_logo {
  list-style: none;
}

.product_skill_logo > li {
  float: left;
  margin-right: 15px;
  margin-bottom: 5px;
}

.product_skill_logo img {
  height: 40px;
}

.product_skill_logo > li::after {
  clear: both;
}
