/* 画像付きのカード */
.img_card {
  position: relative;
  float: left;
  width: 280px;
  height: 380px;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0 3px 6px #ccc;
}

.img_card a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img_card:hover {
  text-decoration: underline;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  transform: scale(1.02, 1.02);
}

.img_card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.img_card_title {
  font-size: 150%;
}

/* 画面幅580px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 580px) {
  /* InfoCard style */
  .img_card {
    width: 90%;
  }
}
