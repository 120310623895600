
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  1% {
    display: block;
  }

  100% {
    opacity: 1;
  }
}

.list_items.show {
  display: block;
  animation: fadeIn 1s;
}

.hard_title {
  display: flex;
}

.close_btn {
  padding: 0;
  margin-top: -5px;
  font-family: inherit;
  font-size: 100%;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
}

.textBtn {
  padding: 0;
  margin-top: -5px;
  font-family: inherit;
  font-size: 100%;
  text-decoration: none;
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
}

.opn_cls_icn {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  content: '';
  background-size: contain;
}

.elemHide {
  display: none;
}
