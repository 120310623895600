.TitleLogo {
  position: absolute;
  top: 40%;	/* headerブロックに対して上から40%の場所に配置 */
  left: 31%;	/* headerブロックに対して左から32%の場所に配置 */
  z-index: 1;
  width: 35%;	/* 画像幅 */
  margin-bottom: 40px;	/* 画像の下に空けるスペース */
}

/* 画面幅580px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 580px) {
  .TitleLogo {
    top: 35%;	/* headerブロックに対して上から40%の場所に配置 */
    width: 45%;	/* 画像幅 */
  }
}
